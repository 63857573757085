<template>
      <v-app class>
        <div>
          <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
    </v-alert>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <v-container>
              <div class="mb-4 mt-8 d-flex">
                <div class="col-md-11">
                  <h1>Medical Screening Records</h1>
                </div>
               <div class="col-md-1">
                  <Button :btnType="'Submit'" :label="'+'" @onClick="addNew" class="mr-2"/>
               </div>
              </div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <div v-if="isLoading" class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary">
                </v-progress-circular>
              </div>
              <v-data-table
                :loading="isLoading"
                loading-text="Fetching records... Please wait"
                :headers= "headers"
                :items="medicalRecords"
                :search="search"
              >
                <template v-slot:item.status="{ item }">
                  {{ formatStatus(item.status) }}
                </template>
                <template v-slot:item.actions="{ item }" >
                  <div class="d-flex" v-if="item.status === '0'">
                    <Button :btnType="'Cancel'" :label="'Cancel'" @onClick="openCancel(item)" class="mr-2"/>
                  </div>
                </template>
              </v-data-table>

              <!-- confirm delete modal  -->
              <Dialog ref="deleteModal" :title="'Are you sure you want to cancel this medical record'" :width="420">
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Yes'" @onClick="cancelRecord" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'No'" @onClick="cancel('deleteModal')" />
                </template>
              </Dialog>
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>
// import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import { medicalScreeningService } from '@/services'
import moment from 'moment'
// import { UPDATE_JOBTITLE, FETCH_EMPLOYEES, DELETE_JOBTITLE } from '@/store/action-type'
export default {
  components: {
    Button,
    Dialog
  },
  data () {
    return {
      search: '',
      isLoading: false,
      formData: {
        name: '',
        id: ''
      },
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      medicalRecords: [],
      headers: [
        {
          text: 'Screening Name',
          value: 'screeningName'
        },
        {
          text: 'Comment',
          value: 'comment'
        },
        {
          text: 'Checkup Date',
          value: 'checkupDate'
        },
        {
          text: 'Personnel',
          value: 'personnelName'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    // employees () {
    //   return this.$store.getters.employees
    // }
  },
  methods: {
    addNew () {
      this.$router.push('/medical/add')
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    openCancel (item) {
      this.$refs.deleteModal.toggleModal()
      // this.formData.name = item.name
      this.formData.id = item.id
    },
    cancelRecord () {
      // const approvals = []
      const that = this
      // approvals.push({
      //   id: this.formData.id,
      //   status: 7,
      //   comments: ''
      // })

      medicalScreeningService.cancelMedicalScreening(this.formData.id).then(() => {
        this.showAlertMessage('Screening successfully cancelled', 'success')
        this.getMedicalRecords()
        that.$refs.deleteModal.toggleModal()
      }).catch(() => {
        this.showAlertMessage('Unable to cancel screening', 'error')
      })
    },
    cancel (modal) {
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.toggleModal()
      }
    },
    formatStatus (status) {
      switch (status) {
        case '0':
          return 'Pending'
        case '1':
          return 'Approved'
        case '2':
          return 'Rejected'
        case '7':
          return 'Cancelled'
        default:
          return ''
      }
    },
    getMedicalRecords () {
      medicalScreeningService.getMedicalScreenings().then(result => {
        this.isLoading = true
        const items = result.data.items
        const records = []
        items.forEach(item => {
          records.push({
            id: item.id,
            status: item.status,
            comment: item.comment,
            screeningName: item.medicalScreeningCategory.name,
            checkupDate: moment(item.checkupDate).format('DD-MMM-yyyy'),
            personnelName: `${item.employee.firstName}, ${item.employee.lastName} ${item.employee.middleName}`
          })
        })

        this.medicalRecords = records
        this.isLoading = false
      })
    }
  },
  mounted () {
    this.getMedicalRecords()
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
